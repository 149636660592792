import { render, staticRenderFns } from "./test_pay.vue?vue&type=template&id=103625bd&lang=pug&"
import script from "./test_pay.vue?vue&type=script&lang=js&"
export * from "./test_pay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\git\\front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('103625bd')) {
      api.createRecord('103625bd', component.options)
    } else {
      api.reload('103625bd', component.options)
    }
    module.hot.accept("./test_pay.vue?vue&type=template&id=103625bd&lang=pug&", function () {
      api.rerender('103625bd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/register_user/test_pay.vue"
export default component.exports