<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          h1 決済テスト用ページ
          form.needs-validation(@submit="exec()" ref="form" method="POST" novalidate)
            .form-group
              label(for="url") 接続先
              select#ur.form-control(name="url" v-model="url" @change="changeUrl")
                option(value="接続支援サイト") 接続支援サイト
                option(value="試験環境") 試験環境
                option(value="試験環境(顧客情報削除)") 試験環境(顧客情報削除)
            .form-group
              label(for="payMethod") 支払方法
              select#payMethod.form-control(name="pay_method" ref="payMethodText" v-model="payMethod" @change="changeUrl")
                option(value="credit") credit
                option(value="docomo") docomo
                option(value="auone") auone
                option(value="softbank2") softbank2
              input(type="text" name="pay_method" ref="payMethodText" v-model="payMethod")
            .form-group
              label マーチェントＩＤ
              input.form-control(type="text" name="merchant_id" ref="merchantId")
            .form-group
              label サービスタイプＩＤ
              input.form-control(type="text" name="service_id" value="001") 
            .form-group
              label 顧客ＩＤ
              input.form-control(type="text" name="cust_code" v-model="cust_code")
            .form-group
              label SBPS顧客ID
              input.form-control(type="text" name="sps_cust_no" value="")
            .form-group
              label SBPS支払い方法管理番号
              input.form-control(type="text" name="sps_payment_no" value="")
            .form-group
              label 購入ＩＤ
              input.form-control(type="text" name="order_id" ref="orderId")
            .form-group
              label 商品ＩＤ
              input.form-control(type="text" name="item_id" ref="itemId")
            .form-group
              label 商品名
              input.form-control(type="text" name="item_name" ref="itemName")
            .form-group
              label サービスタイプ
              input.form-control(type="text" name="service_type" ref="serviceType")
            .form-group
              label トラッキングＩＤ
              input.form-control(type="text" name="tracking_id" ref="trackingId")
            .form-group
              label 顧客利用端末タイプ
              input.form-control(type="text" name="terminal_type" ref="terminalType")
            .form-group
              label 決済完了時ＵＲＬ
              input.form-control(type="text" name="success_url" ref="successUrl")
            .form-group
              label 決済キャンセル時ＵＲＬ
              input.form-control(type="text" name="cancel_url" ref="cancelUrl")
            .form-group
              label エラー時　ＵＲＬ
              input.form-control(type="text" name="error_url" ref="errorUrl")
            .form-group
              label 決済通知用ＣＧＩ
              input.form-control(type="text" name="pagecon_url" ref="pageconUrl")
              input.form-control(type="text" name="request_date" ref="requestDate")
              input.form-control(type="text" name="limit_second" value="600")
              input.form-control(type="text" name="sps_hashcode" ref="hashcode")
            .form-group
              input.btn.btn-primary(type="submit" value="送信" v-bind:disabled="isDisabled")
            
</template>

<script>
import { createHash } from 'crypto'

export default {
  data() {
    return {
      url: this.url,
      payMethod: this.payMethod,
      hash: this.url == '接続支援サイト' ? '398a58952baf329cac5efbae97ea84ba17028d02':'a8f475ec1efeb4e978e8869fa11ad9e6a733b2e6',
      isDisabled: false,
      cust_code: this.$user.userId
    }
  },
  mounted() {
    this.$refs.successUrl.value = location.origin + '/register_user/complete'
    this.$refs.cancelUrl.value = location.origin + '/register_user/complete_free'
    this.$refs.errorUrl.value = location.origin + '/'
    this.$refs.pageconUrl.value = process.env.VUE_APP_API_GATEWAY + '/api-pay'
  },
  methods: {
    changeUrl() {
      if(this.url == '接続支援サイト') {
        this.$refs.merchantId.value = '19788'
        this.$refs.form.action = "https://stbfep.sps-system.com/Extra/BuyRequestAction.do"
        this.$refs.serviceType.value = '1'
      }else if(this.url == '試験環境') {
        this.$refs.merchantId.value = '74522'
        //試験環境でクレジット決済の場合
        if(this.payMethod == 'credit') {
          this.$refs.form.action = process.env.VUE_APP_PAY_CREDIT
          this.$refs.serviceType.value = ''
        }else {
          //キャリア決済の場合
          this.$refs.form.action = process.env.VUE_APP_PAY_CARRIER
          this.$refs.serviceType.value = '0'
        }
      }else if(this.url == '試験環境(顧客情報削除)') {
        this.$refs.merchantId.value = '74522'
       //試験環境でクレジット決済の場合
        if(this.payMethod == 'credit') {
          this.$refs.form.action = process.env.VUE_APP_PAY_CREDIT_DEL
          this.$refs.serviceType.value = ''
          this.$refs.trackingId.value = ''
        }else {
          //キャリア決済の場合
          this.$refs.form.action = process.env.VUE_APP_PAY_CARRIER
          this.$refs.serviceType.value = '1'
          this.$refs.trackingId.value = '79869955613788'
        }
      }
      if(this.payMethod == 'credit') {
        this.$refs.itemId.value = ''
        this.$refs.itemName.value = ''
        this.$refs.terminalType.value = '0'
      }else {
        this.$refs.itemId.value = '001'
        this.$refs.itemName.value = 'plan1'
        this.$refs.terminalType.value = '0'
      }
    },
    exec(isPay) {
      //日付取得
      let date = new Date () 

      let year = date.getFullYear() 	// 年
      let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
      let day = ('0' + date.getDate()).slice(-2) //日
      let hour = ('0' + date.getHours()).slice(-2) 	// 時
      let minute = ('0' + date.getMinutes()).slice(-2)	// 分
      let second = ('0' + date.getSeconds()).slice(-2) 	// 秒

      this.$refs.requestDate.value = '' + year + month + day + hour + minute + second

      this.$refs.payMethodText.value = this.payMethod

      if(this.payMethod == 'credit') {
        this.$refs.orderId.value = ''
      }else {
        this.$refs.orderId.value = '' + year + month + day + hour + minute + second
      }

      // ハッシュ値設定
      this.createCheckHash(this.$refs.form)
    },
    createCheckHash(e) {
      const hash = this.hash
      let str = ''
      let formGroups = document.getElementsByClassName('form-group')
      formGroups.forEach(f => {
          f.children.forEach(c => {
              if (c.type !== 'text') return
              str += c.value
          })
      })
      let shasum = createHash('sha1')
      shasum.update(str + hash)      
      console.log(str)
      this.$refs.hashcode.value = shasum.digest('hex')
    }
  }
}
</script>